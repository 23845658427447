/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";

import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// @mui icons
import Icon from "@mui/material/Icon";
import Role from "layouts/role";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import User from "layouts/user";
import EditRole from "layouts/edit_role";
import Activities from "layouts/activities";
import Evidence from "layouts/evidence";
import Participant from "layouts/participants";
// import usePermission from "hooks/usePermission";

// let usePermission;

// try {
//   usePermission = require("hooks/usePermission").default;
// } catch (error) {
//   console.error("usePermission is not defined. Please check your import.");
//   // Manejar el error o proporcionar un valor por defecto
//   usePermission = false;
// }

// const canListAdministradores =
//   usePermission !== false ? (usePermission("Listar", "Administradores") ? true : false) : false;
// const canListRoles =
//   usePermission !== false ? (usePermission("Listar", "Roles") ? true : false) : false;
// const canListActivities =
//   usePermission !== false ? (usePermission("Listar", "Actividades") ? true : false) : false;
// const canListEvidence =
//   usePermission !== false ? (usePermission("Listar", "Evidencias") ? true : false) : false;
// const canListParticipants =
//   usePermission !== false ? (usePermission("Listar", "Participantes") ? true : false) : false;

// const routes = [
//   {
//     // type: "collapse",
//     name: "Bienvenida",
//     key: "bienvenida",
//     icon: <Icon fontSize="small">dashboard</Icon>,
//     route: "/bienvenida",
//     component: <Dashboard />,
//     private: true,
//   },
//   // {
//   //   type: "collapse",
//   //   name: "Tables",
//   //   key: "tables",
//   //   icon: <Icon fontSize="small">table_view</Icon>,
//   //   route: "/tables",
//   //   component: <Tables />,
//   //   private: true,
//   // },
//   // {
//   //   type: "collapse",
//   //   name: "Billing",
//   //   key: "billing",
//   //   icon: <Icon fontSize="small">receipt_long</Icon>,
//   //   route: "/billing",
//   //   component: <Billing />,
//   //   private: true,
//   // },
//   // {
//   //   type: "collapse",
//   //   name: "RTL",
//   //   key: "rtl",
//   //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
//   //   route: "/rtl",
//   //   component: <RTL />,
//   //   private: true,
//   // },
//   // {
//   //   type: "collapse",
//   //   name: "Notifications",
//   //   key: "notifications",
//   //   icon: <Icon fontSize="small">notifications</Icon>,
//   //   route: "/notifications",
//   //   component: <Notifications />,
//   //   private: true,
//   // },
//   // {
//   //   type: "collapse",
//   //   name: "Profile",
//   //   key: "profile",
//   //   icon: <Icon fontSize="small">person</Icon>,
//   //   route: "/profile",
//   //   component: <Profile />,
//   //   private: true,
//   // },
//   {
//     // type: "collapse",
//     name: "Sign In",
//     key: "sign-in",
//     icon: <Icon fontSize="small">login</Icon>,
//     route: "/authentication/sign-in",
//     component: <SignIn />,
//   },
//   // {
//   //   type: "collapse",
//   //   name: "Sign Up",
//   //   key: "sign-up",
//   //   icon: <Icon fontSize="small">assignment</Icon>,
//   //   route: "/authentication/sign-up",
//   //   component: <SignUp />,
//   // },
//   ...(canListRoles
//     ? [
//         {
//           type: "collapse",
//           name: "Roles",
//           key: "Roles",
//           icon: <SwitchAccountIcon fontSize="small">group</SwitchAccountIcon>,
//           route: "/Roles",
//           component: <Role />,
//           private: true,
//         },
//       ]
//     : []),
//   ...(canListAdministradores
//     ? [
//         {
//           type: "collapse",
//           name: "Administradores",
//           key: "Administradores",
//           icon: <ManageAccountsIcon fontSize="small">group</ManageAccountsIcon>,
//           route: "/Administradores",
//           component: <User />,
//           private: true,
//         },
//       ]
//     : []),
//   ...(canListRoles
//     ? [
//         {
//           type: "route", // Esto es para que no aparezca en el Sidenav
//           name: "Edit Role",
//           key: "edit-role",
//           route: "/Roles/:id", // Ruta para la edición de roles
//           component: <EditRole />,
//           private: true,
//         },
//       ]
//     : []),

//   ...(canListActivities
//     ? [
//         {
//           type: "collapse",
//           name: "Actividades",
//           key: "Actividades",
//           icon: <ManageAccountsIcon fontSize="small">group</ManageAccountsIcon>,
//           route: "/Actividades",
//           component: <Activities />,
//           private: true,
//         },
//       ]
//     : []),

//   ...(canListActivities
//     ? [
//         {
//           name: "Actividades-E",
//           key: "Actividades-E",
//           route: "/Actividades-E",
//           component: <Activities />,
//           private: true,
//         },
//       ]
//     : []),

//   ...(canListActivities
//     ? [
//         {
//           name: "Actividades-P",
//           key: "Actividades-P",
//           route: "/Actividades-P",
//           component: <Activities />,
//           private: true,
//         },
//       ]
//     : []),

//   ...(canListEvidence
//     ? [
//         {
//           type: "route", // Esto es para que no aparezca en el Sidenav
//           name: "Evidencias",
//           key: "evidence",
//           route: "/Actividades-E/:id", // Ruta para la edición de roles
//           component: <Evidence />,
//           private: true,
//         },
//       ]
//     : []),

//   ...(canListParticipants
//     ? [
//         {
//           type: "route", // Esto es para que no aparezca en el Sidenav
//           name: "Participantes",
//           key: "participants",
//           route: "/Actividades-P/:id", // Ruta para la edición de participantes
//           component: <Participant />,
//           private: true,
//         },
//       ]
//     : []),
// ];

// export default routes;
const baseRoutes = [
  {
    name: "Bienvenida",
    key: "bienvenida",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/bienvenida",
    component: <Dashboard />,
    private: true,
  },
  {
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
];

const roleRoutes = [
  {
    type: "collapse",
    name: "Roles",
    key: "Roles",
    icon: <SwitchAccountIcon fontSize="small">group</SwitchAccountIcon>,
    route: "/Roles",
    component: <Role />,
    private: true,
  },
  {
    type: "route",
    name: "Edit Role",
    key: "edit-role",
    route: "/Roles/:id",
    component: <EditRole />,
    private: true,
  },
];

const adminRoutes = [
  {
    type: "collapse",
    name: "Administradores",
    key: "Administradores",
    icon: <ManageAccountsIcon fontSize="small">group</ManageAccountsIcon>,
    route: "/Administradores",
    component: <User />,
    private: true,
  },
];

const activityRoutes = [
  {
    type: "collapse",
    name: "Actividades",
    key: "Actividades",
    icon: <ManageAccountsIcon fontSize="small">group</ManageAccountsIcon>,
    route: "/Actividades",
    component: <Activities />,
    private: true,
  },
  {
    name: "Actividades-E",
    key: "Actividades",
    route: "/Actividades-E",
    component: <Activities />,
    private: true,
  },
  {
    name: "Actividades-P",
    key: "Actividades",
    route: "/Actividades-P",
    component: <Activities />,
    private: true,
  },
];

const evidenceRoutes = [
  {
    type: "route",
    name: "Evidencias",
    key: "Actividades",
    route: "/Actividades-E/:id",
    component: <Evidence />,
    private: true,
  },
];

const participantRoutes = [
  {
    type: "route",
    name: "Participantes",
    key: "Actividades",
    route: "/Actividades-P/:id",
    component: <Participant />,
    private: true,
  },
];

export { baseRoutes, roleRoutes, adminRoutes, activityRoutes, evidenceRoutes, participantRoutes };
