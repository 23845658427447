import { fetchWrapper } from "fetchWrapper";

export async function SystemStatisticListService(pageIndex) {
  try {
    const url = `${process.env.REACT_APP_BACKEND_URL}/statistics/`;
    const result = await fetchWrapper(url, { method: "GET" });
    return result;
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    return { success: false, message: error.message || message };
  }
}
