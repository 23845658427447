import { fetchWrapper } from "fetchWrapper";

export const ParticipantCreateService = async (payload) => {
  try {
    const response = await fetchWrapper(
      `${process.env.REACT_APP_BACKEND_URL}/participants/create/`,
      {
        method: "POST",
        body: JSON.stringify(payload),
      }
    );
    // if (response.ok) {
    // const result = await response.json();
    return response;
    // } else if (response.status == 500) {
    //   const result = await response.json();
    //   return { success: false, message: result.error };
    // } else {
    //   throw new Error(`Error del servidor al realizar la solicitud`);
    // }
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    return { success: false, message: error.message || message };
  }
};

export async function ParticipantListService(id, pageIndex, pageSize) {
  try {
    const response = await fetchWrapper(
      `${process.env.REACT_APP_BACKEND_URL}/participants/listar/${id}/?page=${pageIndex}&pageSize=${pageSize}`,
      {
        method: "GET",
      }
    );
    // if (response.ok) {

    return response;
    // } else {
    //   throw new Error(`Error del servidor al realizar la solicitud`);
    // }
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    return { success: false, message: error.message || message };
  }
}

//consultar el endpoint que me retornara si se creo correctamente la fuente de datos o error si ocurre y retornar esos datos
export const participantUploadService = async (data) => {
  try {
    const response = await fetchWrapper(
      `${process.env.REACT_APP_BACKEND_URL}/participants/upload/`,
      {
        method: "POST",
        body: data,
      },
      true
    );

    // if (response.ok) {
    // const result = await response.json();

    return response;
    // } else {
    //   throw new Error(`Error del servidor al realizar la solicitud`);
    // }
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    const status = false;
  }
};

export const ParticipantUpdateService = async (id, payload) => {
  try {
    const response = await fetchWrapper(
      `${process.env.REACT_APP_BACKEND_URL}/participants/update/${id}/`,
      {
        method: "PUT",
        body: JSON.stringify(payload),
      }
    );

    // if (response.ok) {
    // const result = await response.json();
    return response;
    // } else {
    //   const errorText = await response.text();
    //   throw new Error(`Error del servidor al realizar la solicitud: ${errorText}`);
    // }
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    return { success: false, message: error.message || message };
  }
};

export const fetchParticipantByDocument = async (document) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/participants/get_by_document/${document}/`
    );
    if (!response.ok) {
      throw new Error("Participante no encontrado");
    }
    const data = await response.json();
    return { success: true, data: data.payload[0] }; // Asumiendo que la respuesta contiene un campo 'payload'
  } catch (error) {
    return { success: false, message: error.message || "Error en la búsqueda" };
  }
};
