/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import React from "react"; // Asegúrate de importar React

function DataTableBodyCell({ noBorder, align, maxWidth, children }) {
  // Función para extraer texto desde `children`, incluso si es un nodo React complejo
  const extractText = (child) => {
    if (typeof child === "string") {
      return child;
    }
    if (React.isValidElement(child)) {
      return React.Children.toArray(child.props.children).map(extractText).join("");
    }
    return "";
  };

  const cellText = extractText(children);
  return (
    <MDBox
      component="td"
      textAlign={align}
      py={1.5}
      px={3}
      sx={({ palette: { light }, typography: { size }, borders: { borderWidth } }) => ({
        fontSize: size.sm,
        borderBottom: noBorder ? "none" : `${borderWidth[1]} solid ${light.main}`,
        maxWidth: maxWidth, // Limita el ancho de la celda
        overflow: "hidden", // Oculta el contenido que excede el ancho
        textOverflow: "ellipsis", // Agrega puntos suspensivos para texto truncado
        whiteSpace: "nowrap", // Evita el salto de línea
      })}
      title={cellText} // Usa `cellText` para mostrar solo el texto en el tooltip
    >
      <MDBox
        display="inline-block"
        width="100%"
        maxWidth={maxWidth}
        color="text"
        sx={{
          verticalAlign: "middle",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {children}
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of DataTableBodyCell
DataTableBodyCell.defaultProps = {
  noBorder: false,
  align: "left",
  maxWidth: "none", // Valor por defecto para maxWidth
};

// Typechecking props for the DataTableBodyCell
DataTableBodyCell.propTypes = {
  children: PropTypes.node.isRequired,
  noBorder: PropTypes.bool,
  align: PropTypes.oneOf(["left", "right", "center"]),
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Validación de tipo para maxWidth
};

export default DataTableBodyCell;
