/* 
██╗██╗░░░██╗  ██████╗░██╗░██████╗░██╗████████╗░█████╗░██╗░░░░░
██║██║░░░██║  ██╔══██╗██║██╔════╝░██║╚══██╔══╝██╔══██╗██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░██╗░██║░░░██║░░░███████║██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░╚██╗██║░░░██║░░░██╔══██║██║░░░░░
██║╚██████╔╝  ██████╔╝██║╚██████╔╝██║░░░██║░░░██║░░██║███████╗
╚═╝░╚═════╝░  ╚═════╝░╚═╝░╚═════╝░╚═╝░░░╚═╝░░░╚═╝░░╚═╝╚══════╝
╔════════════════════════════════════════════════════════════╗
║              EQUIPO DE DESARROLLO DE SOFTWARE              ║
║                   MODULO DESARROLLADO POR                  ║
╟┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄╢
║ Juan Esteban Atehortua Sanchez                             ║
║ juan.atehortua@iudigital.edu.co                            ║
╚════════════════════════════════════════════════════════════╝
*/

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import MDButton from "components/MDButton";
import PropTypes from "prop-types";

export function EvidenceDelete({ openDelete, closeDelete, infoDelete, submitDelete }) {
  return (
    <>
      <Dialog
        open={openDelete}
        onClose={closeDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ textAlign: "center" }}
      >
        <DialogTitle id="alert-dialog-title">{"¡Importante!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Está seguro de eliminar esta evidencia?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={closeDelete}>Cancelar</MDButton>
          <MDButton color="warning" onClick={submitDelete}>
            Eliminar
          </MDButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
