import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  RoleByIDService,
  RoleUpdateService,
  PermissionListService,
  RolePermissionsService,
  updateRolePermissions,
  PermissionsByRoleId,
} from "../services/EditRoleService";
import { EditRole } from "../modules/EditRole";
import Spinner from "components/MDSpinner";
import MDAlert from "components/Alert";
import { usePermissions } from "context";

export default function RoleEditController() {
  const { id } = useParams(); // Obtener el ID del rol desde la URL
  const navigate = useNavigate(); // Para redirigir después de guardar
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState({});
  const [permissions, setPermissions] = useState([]);
  const [permissionsByRol, setPermissionsByRol] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [message, setMessage] = useState("");
  const [isAlert, setIsAlert] = useState(false);
  const [isFormModified, setIsFormModified] = useState(false);
  const { updatePermissions } = usePermissions(); // Obtener la función para actualizar permisos

  const fetchRoleAndPermissions = async () => {
    try {
      const roleResult = await RoleByIDService(id);
      if (roleResult) {
        setRole(roleResult.payload);
        setSelectedPermissions(roleResult.payload.permissions || []);
      }

      // Llama al servicio RolePermissionsService
      const rolePermissionsResult = await RolePermissionsService(id);
      if (rolePermissionsResult.success) {
        setPermissionsByRol(rolePermissionsResult.payload);
      }

      // Si también necesitas cargar todos los permisos basados en software_id
      const software_id = sessionStorage.getItem("software_id");
      const permissionsResult = await PermissionListService(software_id);
      if (permissionsResult.success) {
        setPermissions(permissionsResult.payload);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  //Obtener los permisos por rol
  const fetchPermissionsByRol = async () => {
    try {
      let role = JSON.parse(sessionStorage.getItem("role"));

      const { id } = role;
      const roleResult = await PermissionsByRoleId(id);
      if (roleResult.success) {
        // const permissions = JSON.stringify(roleResult.payload); // Convierte el array de permisos a JSON
        sessionStorage.setItem("permissions", JSON.stringify(roleResult.payload));
        console.log("Mandar solicutud de actualizar permisos");
        updatePermissions(JSON.stringify(roleResult.payload));
      } else {
        console.error(roleResult.message || "Error al obtener los permisos por rol");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch data when the component is mounted
  useEffect(() => {
    fetchRoleAndPermissions();
  }, [id]);

  // Update the field value when user changes it
  const handleFieldChange = (field, value) => {
    setRole({ ...role, [field]: value });
    setIsFormModified(true);
    handleChangeValid(field, value);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    //e.preventDefault();
    // Verificar si hay al menos un campo del modal tiene errores
    const hasErrors = hasInvalidFields(isFieldValid);

    if (hasErrors) {
      handleAlert("fail", "Hay al menos un error en los campos ingresados");
      return;
    }

    setLoading(true);
    try {
      // Excluir software_id del objeto role
      const updatedRole = {
        ...role,
        software_id: sessionStorage.getItem("software_id"), // Obtener el software_id desde localStorage
      };

      const result = await RoleUpdateService(id, updatedRole);
      if (result.success) {
        handleAlert("success", "El rol se ha actualizado correctamente");
        navigate("/Roles"); // Redirige a la lista de roles después de guardar
      } else {
        handleAlert("fail", result.message);
      }
    } catch (error) {
      handleAlert("fail", error.message || "Error al actualizar el rol");
    } finally {
      setLoading(false);
    }
  };

  // Handle updating permissions
  const handleUpdatePermissions = async (permissions) => {
    setLoading(true);
    try {
      const result = await updateRolePermissions(id, permissions);
      if (result.success) {
        await fetchRoleAndPermissions(); // Esperar a que se recarguen los permisos
        await fetchPermissionsByRol(); // Esperar a que se recarguen los permisos por rol
        handleAlert("success", "Permisos actualizados correctamente");
      } else {
        handleAlert("fail", result.message);
      }
    } catch (error) {
      handleAlert("fail", error.message || "Error al actualizar los permisos");
    } finally {
      setLoading(false);
      handleSubmit();
    }
  };

  // Handle alerts
  const handleAlert = (typeAlert, message) => {
    setMessage({ type: typeAlert, text: message });
    setIsAlert(true);
  };

  // Estado de las validaciones
  const [isFieldValid, setIsFieldValid] = useState({
    name: true,
  });

  // Función para verificar si algún campo no es válido
  const hasInvalidFields = (fields) => {
    return Object.values(fields).some((value) => value === false);
  };

  // Setea los valores de las validaciones
  const resetFieldValid = () => {
    setIsFieldValid({
      name: true,
    });
  };

  // Expresiones regulares para diferentes tipos de campos
  const validationPatterns = {
    name: /^[a-zA-ZÀ-ÿ\s\u00f1\u00d1]+$/, // Solo permite letras y espacios
  };

  // Compara el valor del input con la expresion regular requerida
  const validateField = (value, pattern) => {
    return pattern.test(value);
  };

  // Comprueba la validez del campo y luego establece la validez en el estado
  const handleChangeValid = (fieldName, value) => {
    const isFieldValid = validateField(value.trim(), validationPatterns[fieldName]);

    // if (!value) {
    //   setIsFieldValid((prevValidity) => ({
    //     ...prevValidity,
    //     [fieldName]: true,
    //   }));
    //   return;
    // }

    setIsFieldValid((prevValidity) => ({
      ...prevValidity,
      [fieldName]: isFieldValid,
    }));
  };

  if (loading) return <Spinner loading={loading} />;

  return (
    <>
      <MDAlert
        isAlert={isAlert}
        setIsAlert={setIsAlert}
        typeAlert={message.type}
        message={message.text}
      />
      <EditRole
        role={role}
        handleRolePermitList={permissions.payload}
        handlePermiByRolList={permissionsByRol.payload}
        handleFieldChange={handleFieldChange}
        isFormModified={isFormModified}
        handleSubmit={handleSubmit}
        isFieldValid={isFieldValid}
        handleUpdatePermissions={handleUpdatePermissions}
      />
    </>
  );
}
