import React, { useEffect, useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import PieChart from "examples/Charts/PieChart";
import DoughnutChart from "examples/Charts/DoughnutCharts/DefaultDoughnutChart"; // Asegúrate de tener este componente
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";
import { SystemStatisticListService } from "./services/DashBoardService";
import MDAlert from "components/Alert";

function Dashboard() {
  const { sales, tasks } = reportsLineChartData;

  const dataDefaultLabel = ["N/A", "N/A", "N/A", "N/A", "N/A", "N/A"];
  const dataDefault = [0.1, 0.1, 0.1, 0.1, 0.1, 0.1];
  // Estados para el tipo de gráfico de cada gráfica
  const [activitiesChartType, setActivitiesChartType] = useState("bar");
  const [evidencesChartType, setEvidencesChartType] = useState("line");
  const [participantsChartType, setParticipantsChartType] = useState("line");

  const currentDate = new Date();
  const lastSemesterDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 6);
  const lastSemesterDateString = lastSemesterDate.toLocaleDateString();
  const [statistics, setStatistics] = useState([]);
  const [typeAlert, setTypeAlert] = useState("");
  const [message, setMessage] = useState("");
  const [isAlert, setIsAlert] = useState(false);

  const transformStatisticsData = (data) => {
    // Inicializar objetos para actividades y participantes
    let activitiesData = [];
    let participantsData = [];
    let activitiesCertificadasData = [];
    let labels = [];
    let totalActivities = 0;
    let totalParticipants = 0;
    let totalActividadesCertificadas = 0;
    let totalActividadesNotCertificadas = 0;
    let activitiesPerMonth = [];
    let participantsPerMonth = [];
    let activitiesCertificadasPerMonth = [];
    let activitiesNotCertificadasPerMonth = [];

    data.forEach((item) => {
      if (item.stat_type === "monthly" && item.stat_labels && item.stat_data) {
        // Para datos mensuales
        labels = item.stat_labels.split(",");
        const dataPoints = item.stat_data.split(",").map(Number);

        if (item.stat_name.includes("Actividades por mes")) {
          activitiesPerMonth = dataPoints.map((value) => (value === 0 ? 0.1 : value));
        } else if (item.stat_name.includes("Participantes por mes")) {
          participantsPerMonth = dataPoints.map((value) => (value === 0 ? 0.1 : value));
        } else if (item.stat_name.includes("Actividades certificadas")) {
          activitiesCertificadasPerMonth = dataPoints.map((value) => (value === 0 ? 0.1 : value));
        } else if (item.stat_name.includes("Actividades no certificadas")) {
          activitiesNotCertificadasPerMonth = dataPoints.map((value) =>
            value === 0 ? 0.1 : value
          );
        }
      }
      if (item.stat_type === "number" && item.stat_value) {
        if (item.stat_name === "Total de actividades") {
          totalActivities = item.stat_value;
        }
      }
      if (item.stat_type === "number" && item.stat_value) {
        if (item.stat_name === "Total de participantes") {
          totalParticipants = item.stat_value;
        }
      }
      if (item.stat_type === "number" && item.stat_value) {
        if (item.stat_name === "Actividades certificadas") {
          totalActividadesCertificadas = item.stat_value;
        }
      }
      if (item.stat_type === "number" && item.stat_value) {
        if (item.stat_name === "Actividades no certificadas") {
          totalActividadesNotCertificadas = item.stat_value;
        }
      }
    });

    // console.log("Labels:", labels);
    // console.log("Data:", activitiesPerMonth);

    return {
      labels,
      activitiesData,
      participantsData,
      activitiesCertificadasData,
      totalActivities,
      totalParticipants,
      totalActividadesCertificadas,
      totalActividadesNotCertificadas,
      activitiesPerMonth,
      participantsPerMonth,
      activitiesCertificadasPerMonth,
      activitiesNotCertificadasPerMonth,
    };
  };

  const requestSystemStatisticsList = async () => {
    const result = await SystemStatisticListService();
    if (result.success) {
      const transformedData = transformStatisticsData(result.payload);
      setStatistics(transformedData);
    } else {
      handleAlert("fail", result.message);
    }
  };

  useEffect(() => {
    requestSystemStatisticsList();
  }, []);

  // Maneja las alertas de exito fallo
  const handleAlert = (typeAlert, message) => {
    setTypeAlert(typeAlert);
    setMessage(message);
    setIsAlert(true);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDAlert
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          typeAlert={typeAlert}
          message={message}
        ></MDAlert>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="weekend"
                title="Total de participantes"
                count={statistics.totalParticipants || 0} // Verificar que participantsData no sea undefined
                percentage={{
                  color: "success",
                  amount: "",
                  label: "último semestre",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="Total de actividades"
                count={statistics.totalActivities || 0} // Verificar que activitiesData no sea undefined
                percentage={{
                  color: "success",
                  amount: "",
                  label: "último semestre",
                }}
              />
            </MDBox>
          </Grid>
          {/* <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Total de evidencias"
                count="34k"
                percentage={{
                  color: "success",
                  amount: "",
                  label: "último semestre",
                }}
              />
            </MDBox>
          </Grid> */}
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Actividades no certificadas"
                count={statistics.totalActividadesNotCertificadas || 0}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "último semestre",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Actividades certificadas"
                count={statistics.totalActividadesCertificadas || 0}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "último mes",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            {/* Gráfico de actividades */}
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                {activitiesChartType === "bar" ? (
                  <ReportsBarChart
                    color="info"
                    title="Total de actividades"
                    description="Total de actividades creadas el último semestre"
                    date={lastSemesterDateString}
                    chart={{
                      labels: statistics.labels ? statistics.labels : dataDefaultLabel, // Labels obtenidos de la API
                      datasets: {
                        label: "Actividades",
                        data: statistics.activitiesPerMonth
                          ? statistics.activitiesPerMonth
                          : dataDefault,
                      }, // Data obtenida de la API
                    }}
                  />
                ) : activitiesChartType === "line" ? (
                  <ReportsLineChart
                    color="info"
                    title="Total de actividades"
                    description="Total de actividades creadas el último semestre"
                    date={lastSemesterDateString}
                    chart={{
                      labels: statistics.labels ? statistics.labels : dataDefaultLabel,
                      datasets: {
                        label: "Actividades",
                        data: statistics.activitiesPerMonth
                          ? statistics.activitiesPerMonth
                          : dataDefault,
                      },
                    }}
                  />
                ) : activitiesChartType === "pie" ? (
                  <PieChart
                    icon={{ component: "pie_chart", color: "info" }}
                    title="Total de actividades"
                    description="Distribución de actividades"
                    height="19.125rem"
                    chart={{
                      labels: statistics.labels ? statistics.labels : dataDefaultLabel, // ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct']
                      datasets: {
                        label: "Actividades",
                        data: statistics.activitiesPerMonth
                          ? statistics.activitiesPerMonth
                          : dataDefault, // [0.1, 0.1, 0.1, 0.1, 0.1, 3]
                        backgroundColors: [
                          "#344767",
                          "#8B0000",
                          "#F5F5F5",
                          "#344767",
                          "#8B0000",
                          "#F5F5F5",
                        ], // Colores para cada segmento
                      },
                    }}
                  />
                ) : (
                  <DoughnutChart
                    icon={{ component: "donut_large", color: "info" }}
                    title="Total de actividades"
                    description="Distribución de actividades"
                    height="19.125rem"
                    chart={{
                      labels: statistics.labels ? statistics.labels : dataDefaultLabel, // ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct']
                      datasets: {
                        label: "Actividades",
                        data: statistics.activitiesPerMonth
                          ? statistics.activitiesPerMonth
                          : dataDefault, // [0.1, 0.1, 0.1, 0.1, 0.1, 3]
                        backgroundColors: [
                          "#344767",
                          "#8B0000",
                          "#F5F5F5",
                          "#344767",
                          "#8B0000",
                          "#F5F5F5",
                        ], // Colores para cada segmento
                      },
                    }}
                  />
                )}
              </MDBox>
              <MDBox mb={1.5}>
                <FormControl fullWidth>
                  <InputLabel id="activities-chart-type-label">Tipo de Gráfico</InputLabel>
                  <Select
                    sx={{ padding: "10px" }}
                    labelId="activities-chart-type-label"
                    value={activitiesChartType}
                    onChange={(event) => setActivitiesChartType(event.target.value)}
                    label="Tipo de Gráfico"
                  >
                    <MenuItem value="bar">Gráfico de Barras</MenuItem>
                    <MenuItem value="line">Gráfico de Líneas</MenuItem>
                    <MenuItem value="pie">Gráfico de Tortas</MenuItem>
                    <MenuItem value="doughnut">Gráfico de Dona</MenuItem>
                  </Select>
                </FormControl>
              </MDBox>
            </Grid>

            {/* Gráfico de evidencias */}
            {/* <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                {evidencesChartType === "bar" ? (
                  <>
                    <ReportsBarChart
                      color="success"
                      title="Total de evidencias"
                      description="Total de evidencias subidas el último semestre"
                      date={lastSemesterDateString}
                      chart={sales}
                    />
                  </>
                ) : evidencesChartType === "line" ? (
                  <ReportsLineChart
                    color="success"
                    title="Total de evidencias"
                    description="Total de evidencias subidas el último semestre"
                    date={lastSemesterDateString}
                    chart={sales}
                  />
                ) : evidencesChartType === "pie" ? (
                  <PieChart
                    icon={{ component: "pie_chart", color: "success" }}
                    title="Total de evidencias"
                    description="Distribución de evidencias"
                    height="19.125rem"
                    chart={sales}
                  />
                ) : (
                  <DoughnutChart
                    icon={{ component: "donut_large", color: "success" }}
                    title="Total de evidencias"
                    description="Distribución de evidencias"
                    height="19.125rem"
                    chart={sales}
                  />
                )}
              </MDBox>
              <MDBox mb={1.5}>
                <FormControl fullWidth>
                  <InputLabel id="evidences-chart-type-label">Tipo de Gráfico</InputLabel>
                  <Select
                    sx={{ padding: "10px" }}
                    labelId="evidences-chart-type-label"
                    value={evidencesChartType}
                    onChange={(event) => setEvidencesChartType(event.target.value)}
                    label="Tipo de Gráfico"
                  >
                    <MenuItem value="bar">Gráfico de Barras</MenuItem>
                    <MenuItem value="line">Gráfico de Líneas</MenuItem>
                    <MenuItem value="pie">Gráfico de Tortas</MenuItem>
                    <MenuItem value="doughnut">Gráfico de Dona</MenuItem>
                  </Select>
                </FormControl>
              </MDBox>
            </Grid> */}
            {/* Gráfico de actividades no cerfificadas inspirado en el de total de evidencias */}
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                {evidencesChartType === "bar" ? (
                  <>
                    <ReportsBarChart
                      color="success"
                      title="Total de actividades no certificadas"
                      description="Total de actividades no certificadas subidas el último semestre"
                      date={lastSemesterDateString}
                      chart={{
                        labels: statistics.labels ? statistics.labels : dataDefaultLabel,
                        datasets: {
                          label: "Actividades",
                          data: statistics.activitiesNotCertificadasPerMonth
                            ? statistics.activitiesNotCertificadasPerMonth
                            : dataDefault, // Datos de prueba
                        },
                      }}
                    />
                  </>
                ) : evidencesChartType === "line" ? (
                  <ReportsLineChart
                    color="success"
                    title="Total de actividades no certificadas"
                    description="Total de actividades no certificadas subidas el último semestre"
                    date={lastSemesterDateString}
                    chart={{
                      labels: statistics.labels ? statistics.labels : dataDefaultLabel,
                      datasets: {
                        label: "Actividades",
                        data: statistics.activitiesNotCertificadasPerMonth
                          ? statistics.activitiesNotCertificadasPerMonth
                          : dataDefault, // Datos de prueba
                      },
                    }}
                  />
                ) : evidencesChartType === "pie" ? (
                  <PieChart
                    icon={{ component: "pie_chart", color: "success" }}
                    title="Total de actividades no certificadas"
                    description="Distribución de actividades no certificadas"
                    height="19.125rem"
                    chart={{
                      labels: statistics.labels ? statistics.labels : dataDefaultLabel, // ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct']
                      datasets: {
                        label: "Actividades",
                        data: statistics.activitiesNotCertificadasPerMonth
                          ? statistics.activitiesNotCertificadasPerMonth
                          : dataDefault, // Datos de prueba, // [0.1, 0.1, 0.1, 0.1, 0.1, 3]
                        backgroundColors: [
                          "#344767",
                          "#8B0000",
                          "#F5F5F5",
                          "#344767",
                          "#8B0000",
                          "#F5F5F5",
                        ], // Colores para cada segmento
                      },
                    }}
                  />
                ) : (
                  <DoughnutChart
                    icon={{ component: "donut_large", color: "success" }}
                    title="Total de actividades no certificadas"
                    description="Distribución de actividades no certificadas"
                    height="19.125rem"
                    chart={{
                      labels: statistics.labels ? statistics.labels : dataDefaultLabel, // ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct']
                      datasets: {
                        label: "Actividades",
                        data: statistics.activitiesNotCertificadasPerMonth
                          ? statistics.activitiesNotCertificadasPerMonth
                          : dataDefault, // [0.1, 0.1, 0.1, 0.1, 0.1, 3]
                        backgroundColors: [
                          "#344767",
                          "#8B0000",
                          "#F5F5F5",
                          "#344767",
                          "#8B0000",
                          "#F5F5F5",
                        ], // Colores para cada segmento
                      },
                    }}
                  />
                )}
              </MDBox>
              <MDBox mb={1.5}>
                <FormControl fullWidth>
                  <InputLabel id="evidences-chart-type-label">Tipo de Gráfico</InputLabel>
                  <Select
                    sx={{ padding: "10px" }}
                    labelId="evidences-chart-type-label"
                    value={evidencesChartType}
                    onChange={(event) => setEvidencesChartType(event.target.value)}
                    label="Tipo de Gráfico"
                  >
                    <MenuItem value="bar">Gráfico de Barras</MenuItem>
                    <MenuItem value="line">Gráfico de Líneas</MenuItem>
                    <MenuItem value="pie">Gráfico de Tortas</MenuItem>
                    <MenuItem value="doughnut">Gráfico de Dona</MenuItem>
                  </Select>
                </FormControl>
              </MDBox>
            </Grid>

            {/* Gráfico de participantes */}
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                {participantsChartType === "bar" ? (
                  <ReportsBarChart
                    color="dark"
                    title="Total de participantes"
                    description="Total de participantes registrados el último semestre"
                    date={lastSemesterDateString}
                    chart={{
                      labels: statistics.labels ? statistics.labels : dataDefaultLabel,
                      datasets: {
                        label: "Participantes",
                        data: statistics.participantsPerMonth
                          ? statistics.participantsPerMonth
                          : dataDefault,
                      },
                    }}
                  />
                ) : participantsChartType === "line" ? (
                  <ReportsLineChart
                    color="dark"
                    title="Total de participantes"
                    description="Total de participantes registrados el último semestre"
                    date={lastSemesterDateString}
                    chart={{
                      labels: statistics.labels ? statistics.labels : dataDefaultLabel,
                      datasets: {
                        label: "Participantes",
                        data: statistics.participantsPerMonth
                          ? statistics.participantsPerMonth
                          : dataDefault,
                      },
                    }}
                  />
                ) : participantsChartType === "pie" ? (
                  <PieChart
                    icon={{ component: "pie_chart", color: "dark" }}
                    title="Total de participantes"
                    description="Distribución de participantes"
                    height="19.125rem"
                    chart={{
                      labels: statistics.labels ? statistics.labels : dataDefaultLabel, // ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct']
                      datasets: {
                        label: "Participantes",
                        data: statistics.participantsPerMonth
                          ? statistics.participantsPerMonth
                          : dataDefault, // [0.1, 0.1, 0.1, 0.1, 0.1, 3]
                        backgroundColors: [
                          "#344767",
                          "#8B0000",
                          "#F5F5F5",
                          "#344767",
                          "#8B0000",
                          "#F5F5F5",
                        ], // Colores para cada segmento
                      },
                    }}
                  />
                ) : (
                  <DoughnutChart
                    icon={{ component: "donut_large", color: "dark" }}
                    title="Total de participantes"
                    description="Distribución de participantes"
                    height="19.125rem"
                    chart={{
                      labels: statistics.labels ? statistics.labels : dataDefaultLabel, // ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct']
                      datasets: {
                        label: "Participantes",
                        data: statistics.participantsPerMonth
                          ? statistics.participantsPerMonth
                          : dataDefault, // [0.1, 0.1, 0.1, 0.1, 0.1, 3]
                        backgroundColors: [
                          "#344767",
                          "#8B0000",
                          "#F5F5F5",
                          "#344767",
                          "#8B0000",
                          "#F5F5F5",
                        ], // Colores para cada segmento
                      },
                    }}
                  />
                )}
              </MDBox>
              <MDBox mb={1.5}>
                <FormControl fullWidth>
                  <InputLabel id="participants-chart-type-label">Tipo de Gráfico</InputLabel>
                  <Select
                    sx={{ padding: "10px" }}
                    labelId="participants-chart-type-label"
                    value={participantsChartType}
                    onChange={(event) => setParticipantsChartType(event.target.value)}
                    label="Tipo de Gráfico"
                  >
                    <MenuItem value="bar">Gráfico de Barras</MenuItem>
                    <MenuItem value="line">Gráfico de Líneas</MenuItem>
                    <MenuItem value="pie">Gráfico de Tortas</MenuItem>
                    <MenuItem value="doughnut">Gráfico de Dona</MenuItem>
                  </Select>
                </FormControl>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
              {/* <Projects /> */}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              {/* <OrdersOverview /> */}
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
