import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "components/MDSpinner";
import MDAlert from "components/Alert";
import EvidenceList from "../modules/EvidenceList";
import { EvidenceCreate } from "../modules/EvidenceCreate";
import {
  createEvidence,
  EvidenceListService,
  deleteEvidence,
} from "../services/EditEvidenceService";
import { EvidenceDelete } from "../modules/EvidenceDelete";
import FilterController from "components/filter/controller/FilterController";

export default function EvidenceController() {
  const filterConfig = [
    { visibleName: "Descripción", dbField: "description", type: "text" },
    { visibleName: "Fecha de creación", dbField: "created", type: "date" },
  ];

  const dataTable = "evidence"; //Tabla indicada
  const { id } = useParams(); // Obtener el ID del rol desde la URL
  const navigate = useNavigate(); // Para redirigir después de guardar
  const [totalPages, setTotalPages] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [entriesStart, setEntriesStart] = useState(0);
  const [entriesEnd, setEntriesEnd] = useState(0);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [isAlert, setIsAlert] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [handleEvidenceList, setEvidenceList] = useState();

  const [openCreate, setOpenCreate] = useState(false);
  const closeCreate = () => setOpenCreate(false);

  // Delete Controllers
  const [openDelete, setOpenDelete] = useState(false);
  const [infoDelete, setInfoDelete] = useState({});
  const closeEvidenceDelete = () => setOpenDelete(false);

  const handleEvidenceDelete = (event) => {
    event.preventDefault();

    const { dataset } = event.currentTarget;
    const data = {
      id: dataset.id,
      description: dataset.description,
    };
    setInfoDelete(data);
    setOpenDelete(true);
  };

  const handleEvidenceCreate = (event) => {
    event.preventDefault();
    resetFieldValid();
    setOpenCreate(true);
  };

  const requestEvidenceList = async (pageIndex) => {
    const result = await EvidenceListService(id, pageIndex, 10);
    if (result.success) {
      const { payload } = result;
      await setEvidenceList(result.payload.data);
      await setTotalPages(payload.total_pages);
      await setTotalData(payload.total);
      await setEntriesStart(payload.from);
      await setEntriesEnd(payload.to);
    } else {
      handleAlert("fail", result.message);
    }
  };

  useEffect(() => {
    requestEvidenceList(currentPage);
  }, [currentPage]);

  const handleEvidenceSubmit = async (formValues) => {
    // Verificar si hay al menos un campo del modal tiene errores
    const hasErrors = hasInvalidFields(isFieldValid);

    if (hasErrors) {
      handleAlert("fail", "Hay al menos un error en los campos ingresados");
      return;
    }
    setLoading(true);
    // Agregar el activity ID al objeto formValues
    const updatedFormValues = {
      ...formValues,
      activity: id,
    };

    // Crear el objeto FormData y agregar los campos
    const formData = new FormData();
    formData.append("description", formValues.description);
    formData.append("activity", id); // Agregar el ID de la actividad
    if (formValues.file) {
      formData.append("file", formValues.file); // Adjuntar el archivo si está presente
    }

    const result = await createEvidence(formData);

    if (result.success) {
      await requestEvidenceList(currentPage);
      //Mandar mensaje de exito para renderizar en la alerta
      handleAlert("success", "Evidencia creada con éxito");
    } else {
      //Mandar mensaje de error para renderizar en la alerta
      handleAlert("fail", result.message);
    }
    closeCreate();
    setLoading(false);
  };

  // Handle alerts
  const handleAlert = (typeAlert, message) => {
    setMessage({ type: typeAlert, text: message });
    setIsAlert(true);
  };

  const handleDeleteEvidence = async () => {
    setLoading(true);
    const result = await deleteEvidence(infoDelete.id);
    if (result.success) {
      await requestEvidenceList(currentPage); // Actualizar la lista después de eliminar
      handleAlert("success", "Evidencia eliminada con éxito.");
    } else {
      handleAlert("fail", result.message);
    }
    closeEvidenceDelete();
    setLoading(false);
  };

  //Cambia el valor de currentPage a la página elegida
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Estado de las validaciones
  const [isFieldValid, setIsFieldValid] = useState({
    description: true,
  });

  // Función para verificar si algún campo no es válido
  const hasInvalidFields = (fields) => {
    return Object.values(fields).some((value) => value === false);
  };

  // Setea los valores de las validaciones
  const resetFieldValid = () => {
    setIsFieldValid({
      description: true,
    });
  };

  // Expresiones regulares para diferentes tipos de campos
  const validationPatterns = {
    description: /^[a-zA-ZÀ-ÿ\s\u00f1\u00d10-9"(),.]*$/, // Solo permite letras y espacios
  };

  // Compara el valor del input con la expresion regular requerida
  const validateField = (value, pattern) => {
    return pattern.test(value);
  };

  // Comprueba la validez del campo y luego establece la validez en el estado
  const handleChangeValid = (fieldName, value) => {
    const isFieldValid = validateField(value.trim(), validationPatterns[fieldName]);

    // if (!value) {
    //   setIsFieldValid((prevValidity) => ({
    //     ...prevValidity,
    //     [fieldName]: true,
    //   }));
    //   return;
    // }

    setIsFieldValid((prevValidity) => ({
      ...prevValidity,
      [fieldName]: isFieldValid,
    }));
  };

  if (loading) return <Spinner loading={loading} />;

  return (
    <>
      <MDAlert
        isAlert={isAlert}
        setIsAlert={setIsAlert}
        typeAlert={message.type}
        message={message.text}
      />
      <FilterController
        filterConfig={filterConfig}
        dataTable={dataTable}
        setTotalData={setTotalData}
        setEntriesEnd={setEntriesEnd}
        setEntriesStart={setEntriesStart}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setTotalPages={setTotalPages}
        setListData={setEvidenceList}
        handleAlert={handleAlert}
        activity_id={id}
      ></FilterController>

      <EvidenceCreate
        open={openCreate}
        handleClose={closeCreate}
        onSubmit={handleEvidenceSubmit}
        isFieldValid={isFieldValid}
        handleChangeValid={handleChangeValid}
      />

      <EvidenceList
        handleEvidenceList={handleEvidenceList}
        handleEvidenceCreate={handleEvidenceCreate}
        handleRoleDelete={handleEvidenceDelete}
        entriesStart={entriesStart}
        entriesEnd={entriesEnd}
        totalData={totalData}
        totalPages={totalPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
      />

      {
        <EvidenceDelete
          openDelete={openDelete}
          closeDelete={closeEvidenceDelete}
          infoDelete={infoDelete}
          submitDelete={handleDeleteEvidence}
        />
      }
    </>
  );
}
