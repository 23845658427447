// import fetchWrapper from "../../../fetchWrapper";
import { fetchWrapper } from "../../../fetchWrapper"; // Importar fetchWrapper

export const UserListService = async (page, pageSize) => {
  try {
    const result = await fetchWrapper(
      `${process.env.REACT_APP_BACKEND_URL}/users/?page=${page}&pageSize=${pageSize}`,
      {
        method: "GET",
      }
    );

    if (result.success) {
      return result;
    } else {
      // Intentar obtener el mensaje de error del cuerpo de la respuesta
      const errorMessage = result.message || "Error del servidor al realizar la solicitud";
      throw new Error(errorMessage);
    }
  } catch (error) {
    // Retornar el error recibido desde el servidor o el mensaje del error capturado
    return { success: false, message: error.message };
  }
};

export const UserByIDService = async (id) => {
  try {
    const result = await fetchWrapper(`${process.env.REACT_APP_API_URL}/users/${id}`, {
      method: "GET",
    });

    return { result };
  } catch (error) {}
};

export const UserCreateService = async (payload) => {
  try {
    const result = await fetchWrapper(`${process.env.REACT_APP_BACKEND_URL}/users/create/`, {
      method: "POST",
      body: JSON.stringify(payload), // Pasar el payload en el body
    });
    // Verificar si la respuesta fue exitosa (código 2xx)
    if (result.success) {
      return result;
    } else {
      // Si el servidor responde con un error, tratar de obtener el mensaje de error
      // const errorMessage = result.message || "Error del servidor al crear el usuario.";
      return result;
    }
  } catch (error) {
    // Capturar cualquier otro error que no venga del servidor (problemas de red, etc.)
    return {
      success: false,
      message: error.message || "Error del servidor al realizar la solicitud",
    };
  }
};

export const UserUpdateService = async (id, payload) => {
  try {
    const result = await fetchWrapper(`${process.env.REACT_APP_BACKEND_URL}/users/${id}/`, {
      method: "PUT",
      body: JSON.stringify(payload),
    });
    // Verificar si la respuesta fue exitosa
    if (result.success) {
      return result;
    } else {
      // Si el servidor responde con un error, tratar de obtener el mensaje de error
      const errorMessage = result.message || "Error del servidor al actualizar el usuario.";
      return result;
    }
  } catch (error) {
    // Capturar cualquier otro error que no venga del servidor (problemas de red, etc.)
    return {
      success: false,
      message: error.message || "Error del servidor al realizar la solicitud",
    };
  }
};

export const UserDeleteService = async (id) => {
  try {
    const result = await fetchWrapper(`${process.env.REACT_APP_BACKEND_URL}/users/delete/${id}/`, {
      method: "DELETE",
    });

    // Maneja la respuesta del servidor
    const { success, detail } = result;

    // Verifica si la respuesta fue exitosa y contiene un estado de éxito
    if (result.ok && success) {
      return { status: true, message: "Usuario deshabilitado exitosamente." };
    } else {
      // Si el servidor responde con un error, muestra el detalle o un mensaje por defecto
      return { status: false, message: detail || "Error del servidor al realizar la solicitud." };
    }
  } catch (error) {
    // Manejo de errores generales (problemas de red, etc.)
    return {
      status: false,
      message: error.message || "Error del servidor al realizar la solicitud.",
    };
  }
};
