import { fetchWrapper } from "../../../fetchWrapper"; // Importar fetchWrapper

export const ActivitiesCreateService = async (payload) => {
  try {
    const result = await fetchWrapper(`${process.env.REACT_APP_BACKEND_URL}/activities/create/`, {
      method: "POST",
      body: JSON.stringify(payload), // Pasar el payload en el body
    });

    return result;
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    return { status: false, message };
  }
};

export const ActivitiesListService = async (pageIndex, pageSize, vista) => {
  try {
    const result = await fetchWrapper(
      `${process.env.REACT_APP_BACKEND_URL}/activities/?page=${pageIndex}&pageSize=${pageSize}&vista=${vista}`,
      {
        method: "GET",
      }
    );

    if (result.success) {
      return result;
    } else {
      throw new Error(`Error del servidor al realizar la solicitud`);
    }
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    return { success: false, message: error.message || message };
  }
};

export const CategoriesListService = async (pageIndex) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/categories/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      throw new Error(`Error del servidor al realizar la solicitud`);
    }
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    return { success: false, message: error.message || message };
  }
};

// export const CategoriesListService = async (categoryId) => {
//   try {
//     // Cambia esta URL según tu configuración y endpoint
//     const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/categories/`, {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//       },
//     });

//     if (response.ok) {
//       const result = await response.json();
//       return { success: true, payload: result };
//     } else {
//       throw new Error(`Error del servidor al realizar la solicitud`);
//     }
//   } catch (error) {
//     const message = "Error del servidor al realizar la solicitud";
//     return { success: false, message: error.message || message };
//   }
// };
