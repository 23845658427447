/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo, useCallback } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 React routes
// import routes from "routes";
import {
  baseRoutes,
  roleRoutes,
  adminRoutes,
  activityRoutes,
  evidenceRoutes,
  participantRoutes,
} from "routes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";
import { usePermissions } from "context"; // Importa el contexto

// Images
import brandWhite from "assets/images/logo-dark.svg";
import brandDark from "assets/images/logo-light.svg";
import usePermission from "hooks/usePermission";

export default function App() {
  // Limpiar todo lo que haya en el localStorage
  localStorage.clear();
  const listarRolesContext = usePermission("Listar", "Roles");
  const listarAdministradoresContext = usePermission("Listar", "Administradores");
  const listarActividadesContext = usePermission("Listar", "Actividades");
  const listarEvidenciasContext = usePermission("Listar", "Evidencias");
  const listarParticipantesContext = usePermission("Listar", "Participantes");
  // const { permissions } = usePermissions(); // Obtén permisos del contexto
  // console.log("Permisos en App.js:");
  // // const [isBienvenida, setIsBienvenida] = useState(false);
  // const [routes, setRoutes] = useState([...baseRoutes]);

  // // Validar que permissions sea un array antes de usar some
  // let permissionsData = permissions;
  // if (typeof permissions === "string") {
  //   try {
  //     permissionsData = JSON.parse(permissions);
  //   } catch (error) {
  //     console.error("Error al parsear los permisos:", error);
  //   }
  // }

  // if (!Array.isArray(permissionsData)) console.error("Los permisos no son un array");
  // const canListRoles =
  //   usePermission("Listar", "Roles") ||
  //   (Array.isArray(permissionsData) &&
  //     permissionsData.some(
  //       (permission) => permission.name === "Listar" && permission.module === "Roles"
  //     ));
  // const canListAdministradores =
  //   usePermission("Listar", "Administradores") ||
  //   (Array.isArray(permissionsData) &&
  //     permissionsData.some(
  //       (permission) => permission.name === "Listar" && permission.module === "Administradores"
  //     ));
  // const canListActivities =
  //   usePermission("Listar", "Actividades") ||
  //   (Array.isArray(permissionsData) &&
  //     permissionsData.some(
  //       (permission) => permission.name === "Listar" && permission.module === "Actividades"
  //     ));
  // const canListEvidence =
  //   usePermission("Listar", "Evidencias") ||
  //   (Array.isArray(permissionsData) &&
  //     permissionsData.some(
  //       (permission) => permission.name === "Listar" && permission.module === "Evidencias"
  //     ));
  // const canListParticipants =
  //   usePermission("Listar", "Participantes") ||
  //   (Array.isArray(permissionsData) &&
  //     permissionsData.some(
  //       (permission) => permission.name === "Listar" && permission.module === "Participantes"
  //     ));

  // // Función para obtener permisos que puedes llamar las veces que necesites
  // const fetchPermissions = useCallback(() => {
  //   let updatedRoutes = [...baseRoutes];

  //   if (canListRoles) {
  //     updatedRoutes = [...updatedRoutes, ...roleRoutes];
  //   }
  //   if (canListAdministradores) {
  //     updatedRoutes = [...updatedRoutes, ...adminRoutes];
  //   }
  //   if (canListActivities) {
  //     updatedRoutes = [...updatedRoutes, ...activityRoutes];
  //   }
  //   if (canListEvidence) {
  //     updatedRoutes = [...updatedRoutes, ...evidenceRoutes];
  //   }
  //   if (canListParticipants) {
  //     updatedRoutes = [...updatedRoutes, ...participantRoutes];
  //   }

  //   setRoutes(updatedRoutes);
  // }, [
  //   canListRoles,
  //   canListAdministradores,
  //   canListActivities,
  //   canListEvidence,
  //   canListParticipants,
  // ]); // useCallback asegura que la función no cambie de referencia en cada render

  // // Ejecutar los permisos cuando se cargue la página
  // useEffect(() => {
  //   console.log("Actualizar permisos por fetch");
  //   if (permissions) {
  //     fetchPermissions(); // Vuelve a ejecutar la función cada vez que cambien los permisos
  //   }
  // }, [permissions, fetchPermissions]);

  const { permissions } = usePermissions(); // Obtén permisos del contexto
  // console.log("Permisos en App.js:", permissions);
  const [isBienvenida, setIsBienvenida] = useState(false);

  // Validar que permissions sea un array antes de usar some
  let permissionsData = permissions;
  if (typeof permissions === "string") {
    try {
      permissionsData = JSON.parse(permissions);
    } catch (error) {
      console.error("Error al parsear los permisos:", error);
    }
  }

  if (!Array.isArray(permissionsData)) console.error("Los permisos no son un array");

  const canListRoles = useMemo(() => {
    return (
      listarRolesContext ||
      (Array.isArray(permissionsData) &&
        permissionsData.some(
          (permission) => permission.name === "Listar" && permission.module === "Roles"
        ))
    );
  }, [permissionsData]);

  const canListAdministradores = useMemo(() => {
    return (
      listarAdministradoresContext ||
      (Array.isArray(permissionsData) &&
        permissionsData.some(
          (permission) => permission.name === "Listar" && permission.module === "Administradores"
        ))
    );
  }, [permissionsData]);

  const canListActivities = useMemo(() => {
    return (
      listarActividadesContext ||
      (Array.isArray(permissionsData) &&
        permissionsData.some(
          (permission) => permission.name === "Listar" && permission.module === "Actividades"
        ))
    );
  }, [permissionsData]);

  const canListEvidence = useMemo(() => {
    return (
      listarEvidenciasContext ||
      (Array.isArray(permissionsData) &&
        permissionsData.some(
          (permission) => permission.name === "Listar" && permission.module === "Evidencias"
        ))
    );
  }, [permissionsData]);

  const canListParticipants = useMemo(() => {
    return (
      listarParticipantesContext ||
      (Array.isArray(permissionsData) &&
        permissionsData.some(
          (permission) => permission.name === "Listar" && permission.module === "Participantes"
        ))
    );
  }, [permissionsData]);

  const routes = useMemo(() => {
    let updatedRoutes = [...baseRoutes];

    if (canListRoles) {
      updatedRoutes = [...updatedRoutes, ...roleRoutes];
    }
    if (canListAdministradores) {
      updatedRoutes = [...updatedRoutes, ...adminRoutes];
    }
    if (canListActivities) {
      updatedRoutes = [...updatedRoutes, ...activityRoutes];
    }
    if (canListEvidence) {
      updatedRoutes = [...updatedRoutes, ...evidenceRoutes];
    }
    if (canListParticipants) {
      updatedRoutes = [...updatedRoutes, ...participantRoutes];
    }

    return updatedRoutes;
  }, [
    canListRoles,
    canListAdministradores,
    canListActivities,
    canListEvidence,
    canListParticipants,
  ]);

  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    const currentUrl = window.location.pathname;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      let isAuthenticated = sessionStorage.getItem("token") || "";
      if (route.route) {
        if (route.private && !isAuthenticated) {
          // Redirige al usuario a la página de inicio de sesión si intenta acceder a una ruta privada sin autenticación o sin permisos
          return (
            <Route
              exact
              path="*"
              element={<Navigate to="authentication/sign-in" />}
              key={route.key}
            />
          );
        } else if (!route.private && isAuthenticated && route.route === "/authentication/sign-in") {
          // Si el usuario ya inició sesión y trata de acceder a la página de inicio de sesión, redirige a la página de bienvenida
          return <Route exact path="*" element={<Navigate to="/bienvenida" />} key={route.key} />;
        } else {
          return <Route exact path={route.route} element={route.component} key={route.key} />;
        }
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="3.5rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        {layout === "bienvenida" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName=""
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {/* {configsButton} */}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/bienvenida" />} />
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "bienvenida" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName=""
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            sx={{
              "& img": {
                width: "100%",
              },
            }}
          />
          <Configurator />
          {/* {configsButton} */}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/bienvenida" />} />
      </Routes>
    </ThemeProvider>
  );
}
