import React, { useState } from "react";
import { Calendar, dayjsLocalizer, Views } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import dayjs from "dayjs";
import "dayjs/locale/es"; // Importa el idioma español para dayjs
import { Button, Box, Modal, Typography, Card, Tab, Tabs, Stack } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Pagination } from "@mui/material";
import { useNavigate } from "react-router-dom";
import usePermission from "hooks/usePermission";
import PropTypes, { string } from "prop-types";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

// Configura dayjs para usar español
dayjs.locale("es");

// Configuración del localizador de fechas en español
const localizer = dayjsLocalizer(dayjs);

// Mensajes en español para el calendario
const messages = {
  allDay: "Todo el día",
  previous: "Anterior",
  next: "Siguiente",
  today: "Hoy",
  month: "Mes",
  week: "Semana",
  day: "Día",
  agenda: "Agenda",
  date: "Fecha",
  time: "Hora",
  event: "Evento",
  noEventsInRange: "No hay eventos en este rango.",
  showMore: (total) => `+ Ver más (${total})`,
};

const ActivitiesList = ({
  handleActivitiesList = [],
  handleActivitiesCreate,
  handleActivitiesDelete,
  handleActivitiesUpdate,
  pageIndexAndPageSize,
  entriesStart,
  entriesEnd,
  totalData,
  totalPages,
  currentPage,
  handlePageChange,
  callHandleClickSubmit,
  valueTab,
  handleChangeTab,
}) => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [currentView, setCurrentView] = useState(Views.AGENDA); // Guarda la vista actual

  // Verificar permisos
  const canCreate = usePermission("Crear", "Actividades");
  const canEdit = usePermission("Editar", "Actividades");
  const canDelete = usePermission("Eliminar", "Actividades");
  const canListEvidence = usePermission("Listar", "Evidencias");
  const canListParticipant = usePermission("Listar", "Participantes");

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? "Fecha inválida" : date.toISOString().slice(0, 10);
  };

  function formatDateT(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Los meses en JavaScript son 0-indexados
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  const handleEventClick = (event, module) => {
    // console.log(event);
    // console.log(event.subcategories === typeof string);
    // Solo mostrar el modal si la vista NO es "Agenda"
    if (module === "table") {
      setSelectedEvent(event);
      setModalOpen(true);
    }
    if (currentView !== Views.AGENDA) {
      setSelectedEvent(event);
      setModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedEvent(null);
  };

  const handleNavigate = (path) => {
    navigate(path);
    handleCloseModal(); // Cerrar el modal al navegar
  };

  const events = handleActivitiesList.map((userMan) => {
    const startDate = new Date(userMan.fecha);

    // Ajustar para la zona horaria local
    const localStartDate = new Date(
      startDate.setHours(startDate.getHours() + startDate.getTimezoneOffset() / 60)
    );

    const event = {
      id: userMan.id,
      title: userMan.title,
      fecha: localStartDate.toLocaleDateString("es-ES"),
      start: localStartDate,
      end: new Date(localStartDate.getTime() + 60 * 60 * 1000), // Añade 1 hora
      description: userMan.description,
      subcategories: userMan.subcategories?.map((subcat) => subcat.subcategory.name).join(", "),
      evidence_formats: userMan.evidence_formats,
      keywords: userMan.keywords,
      participants: userMan.participants,
      is_certifiable: userMan.is_certifiable,
    };

    return event;
  });

  // Modal para mostrar detalles del evento (solo en vista "Mes", "Semana", etc.)
  const EventModal = ({ open, onClose, event }) => {
    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "90%",
      maxWidth: "400px",
      borderRadius: "20px",
      boxShadow: 24,
      p: 4,
    };

    return (
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Card sx={style}>
          <MDBox
            mt={-7}
            color="white"
            bgColor="info"
            variant="gradient"
            borderRadius="lg"
            p={2}
            sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
          >
            <Typography id="modal-title" component="h2">
              Detalles del evento
            </Typography>
            <Button
              sx={{
                fontSize: "1.5rem",
                color: "white",
                minWidth: "unset",
                backgroundColor: "transparent",
                "&:hover": {
                  backgroundColor: "transparent",
                },
                padding: 0,
              }}
              onClick={onClose}
            >
              <span style={{ fontSize: "1.5rem", color: "white" }}>×</span>
            </Button>
          </MDBox>
          <Box mt={2}>
            <Typography variant="body1">
              <strong>Título:</strong> <br />
              {event.title}
            </Typography>
            {/* <Typography variant="body2">
              <strong>Fecha:</strong> <br />
              {event.fecha}
            </Typography> */}
            <Typography variant="body1">
              <strong>Subcategorías:</strong> <br />
              {typeof event.subcategories === "string"
                ? event.subcategories
                : event.subcategories?.map((subcat) => subcat.subcategory.name).join(", ")}
            </Typography>
            <Typography variant="body1">
              <strong>Descripción:</strong> <br />
              {event.description}
            </Typography>
            <Typography variant="body1">
              <strong>Palabra clave:</strong> <br />
              {event.keywords}
            </Typography>
            <Typography variant="body1">
              <strong>Fecha de actividad:</strong> <br />
              {event.fecha.includes("T") ? formatDateT(event.fecha) : event.fecha}
            </Typography>
            <Typography variant="body1">
              <strong>Certificable:</strong> <br />
              {event.is_certifiable ? "Sí" : "No"}
            </Typography>
            <Box mt={2} display="flex" gap={1}>
              {canListEvidence && (
                <MDButton
                  variant="contained"
                  color="info"
                  onClick={() => handleNavigate(`/Actividades-E/${event.id}`)}
                >
                  Ver evidencias
                </MDButton>
              )}
              {canListParticipant && (
                <MDButton
                  variant="contained"
                  color="info"
                  onClick={() => handleNavigate(`/Actividades-P/${event.id}`)}
                >
                  Ver participantes
                </MDButton>
              )}
            </Box>
          </Box>
        </Card>
      </Modal>
    );
  };

  const AgendaEvent = ({ event }) => (
    <MDBox
      display="grid"
      gridTemplateColumns="repeat(4, 1fr)" // Ajusta a 4 columnas
      gap={2}
      padding="10px"
      borderBottom="1px solid #e0e0e0"
    >
      <Typography variant="body2">
        <strong>Título:</strong> <br />
        {event.title}
      </Typography>
      {/* <Typography variant="body2">
        <strong>Fecha:</strong> <br />
        {event.fecha}
      </Typography> */}
      <Typography variant="body2">
        <strong>Subcategorías:</strong> <br />
        {event.subcategories}
      </Typography>
      <Typography variant="body2">
        <strong>Descripción:</strong> <br />
        {event.description}
      </Typography>
      <Typography variant="body2">
        <strong>Palabras clave:</strong> <br />
        {event.keywords}
      </Typography>
      <Typography variant="body2">
        <strong>Certificable:</strong> <br />
        {event.is_certifiable ? "Sí" : "No"}
      </Typography>
      <MDBox display="flex" gap={1}>
        {canListEvidence && (
          <MDButton
            variant="contained"
            color="info"
            onClick={() => navigate(`/Actividades-E/${event.id}`)}
          >
            Ver evidencia
          </MDButton>
        )}
        {canListParticipant && (
          <MDButton
            variant="contained"
            color="info"
            onClick={() => navigate(`/Actividades-P/${event.id}`)}
          >
            Ver Participantes
          </MDButton>
        )}
      </MDBox>
    </MDBox>
  );

  return (
    <div>
      {/* Contenedor para el botón "Crear" */}
      {canCreate && (
        <MDBox style={{ display: "flex", justifyContent: "flex-end", marginRight: "2vw" }}>
          <MDButton onClick={handleActivitiesCreate} variant="contained" color="info">
            Crear
          </MDButton>
          <MDButton
            style={{ marginLeft: "20px" }}
            onClick={callHandleClickSubmit}
            data-export={true}
            variant="contained"
            color="info"
          >
            Exportar
          </MDButton>
        </MDBox>
      )}
      {/* Espacio entre el botón y el calendario */}
      <br />
      <Box sx={{ width: "100%" }}>
        <Tabs value={valueTab} onChange={handleChangeTab} aria-label="wrapped label tabs example">
          <Tab value={0} label="Vista tipo calendario" wrapped />
          <Tab value={1} label="Vista tipo tabla " />
        </Tabs>
      </Box>
      {/* Contenedor para el calendario */}
      {valueTab === 0 && (
        <>
          <MDBox style={{ padding: "20px" }}>
            <Calendar
              localizer={localizer}
              events={events}
              startAccessor="start"
              endAccessor="end"
              defaultView={Views.AGENDA}
              views={[Views.AGENDA, Views.MONTH, Views.WEEK, Views.DAY]}
              length={365}
              onSelectEvent={handleEventClick}
              onView={(view) => setCurrentView(view)}
              formats={{ timeGutterFormat: () => "" }} // Quita el formato de hora en el gutter }}
              components={{
                agenda: {
                  event: AgendaEvent,
                },
              }}
              messages={messages}
              style={{ height: 500, width: "100%" }}
            />
          </MDBox>
        </>
      )}
      {/* Modal de eventos (solo para vista "Mes", "Semana", etc.) */}
      {selectedEvent && currentView !== Views.AGENDA && (
        <EventModal open={modalOpen} onClose={handleCloseModal} event={selectedEvent} />
      )}
      {valueTab === 1 && (
        <>
          <table style={{ tableLayout: "fixed", width: "100%" }}>
            <DataTable
              table={{
                columns: [
                  // { Header: "correo electrónico", accessor: "email" },
                  { Header: "Título", accessor: "title" },
                  { Header: "Fecha", accessor: "fecha" },
                  { Header: "Descripción", accessor: "description", maxWidth: "100px" },
                  { Header: "Palabras clave", accessor: "keywords" },
                  { Header: "Certificable", accessor: "is_certifiable" },
                  { Header: "acciones", accessor: "action", width: "12%", align: "center" },
                ],
                rows: handleActivitiesList
                  ? handleActivitiesList.map((activity) => {
                      return {
                        id: activity.id,
                        title: activity.title,
                        fecha: formatDate(activity.fecha),
                        description: activity.description,
                        keywords: activity.keywords,
                        is_certifiable: activity.is_certifiable ? "Sí" : "No",
                        action: (
                          <>
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                // setModalOpen(true);
                                // setSelectedEvent(activity)
                                setCurrentView("table");
                                handleEventClick(activity, "table");

                                // <EventModal
                                //   open={modalOpen}
                                //   onClose={handleCloseModal}
                                //   event={activity}
                                // />;
                              }}
                            >
                              <VisibilityOutlinedIcon
                                titleAccess="Ver detalles"
                                style={{
                                  fontSize: "200px",
                                  width: "24px",
                                  height: "24px",
                                  backgroundImage: "#000000",
                                }}
                              ></VisibilityOutlinedIcon>
                            </a>
                          </>
                        ),
                      };
                    })
                  : [],
              }}
              canSearch={false}
              isSorted={false}
              entriesPerPage={false}
              showTotalEntries={false}
              pagination={{ variant: "gradient", color: "info" }} // Estilo de paginación
            />
          </table>
          {!handleActivitiesList ? (
            <MDBox style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <MDTypography
                style={{ left: "20px", fontSize: "20px" }}
                variant="button"
                color="secondary"
                fontWeight="regular"
              >
                No hay datos
              </MDTypography>
            </MDBox>
          ) : (
            <>
              {/* Mostrar la paginación */}
              <MDBox style={{ marginLeft: "20px" }} mb={{ xs: 3, sm: 0 }}>
                <MDTypography
                  style={{ left: "20px" }}
                  variant="button"
                  color="secondary"
                  fontWeight="regular"
                >
                  Mostrando {entriesStart} hasta {entriesEnd} de {totalData} resultados en total
                </MDTypography>
              </MDBox>
              <Stack style={{ marginLeft: "20px", marginBottom: "10px" }} spacing={2}>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                ></Pagination>
              </Stack>{" "}
            </>
          )}
        </>
      )}

      {/* Contenedor para la paginación */}
      {/* <MDBox style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </MDBox> */}
    </div>
  );
};

export default ActivitiesList;
