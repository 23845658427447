import React, { useEffect, useState } from "react";
import {
  Box,
  Modal,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Divider,
  Select,
  Card,
  Radio,
  FormControlLabel,
  RadioGroup,
  Chip,
  ListSubheader,
  Checkbox,
  ListItemText,
  List,
  Icon,
  FormHelperText,
} from "@mui/material";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

export function ActivitiesCreate({
  open,
  handleClose,
  isDisabled,
  getFieldValue,
  handleFieldChange,
  handleSubmit,
  isFieldValid,
  listSubCategories = [],
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "400px",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
  };

  const maxLength = 255;
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);

  useEffect(() => {
    // If you need to perform any action when listSubCategories changes
  }, [listSubCategories]);

  const handleChange = (event) => {
    setSelectedSubcategories(event.target.value);
    handleFieldChange("subcategories", event.target.value);
  };

  const renderSelectGroup = (category) => {
    const items = category.subcategories.map((sub) => (
      <MenuItem key={sub.id} value={sub.id}>
        <Checkbox checked={selectedSubcategories.indexOf(sub.id) > -1} />
        <ListItemText primary={sub.name} />
      </MenuItem>
    ));
    return [<ListSubheader key={category.id}>{category.name}</ListSubheader>, items];
  };

  const handleSelectClose = () => {
    setIsSelectOpen(false); // Cierra solo el menú del Select
  };

  useEffect(() => {
    if (open) {
      // Limpiar las subcategorías seleccionadas al abrir el modal
      setSelectedSubcategories([]);
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card sx={style}>
        <MDBox
          mt={-7}
          color="white"
          bgColor="info"
          variant="gradient"
          borderRadius="lg"
          p={2}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Typography id="modal-modal-title" component="h2">
            Crear actividad
          </Typography>
          <Icon
            sx={{
              fontSize: "2em",
              stroke: "currentColor",
              strokeWidth: "2px",
              cursor: "pointer",
              marginLeft: "auto",
            }}
            onClick={handleClose}
          >
            close
          </Icon>
        </MDBox>
        <Divider variant="middle" />
        <form onSubmit={handleSubmit}>
          <Box mb={2}>
            <TextField
              fullWidth
              label="Fecha"
              type="date"
              InputLabelProps={{ shrink: true }}
              name="fecha"
              value={getFieldValue("fecha")}
              error={!isFieldValid.fecha}
              helperText={
                getFieldValue("fecha") === "" && !isFieldValid.fecha
                  ? "El campo no puede estar vacío"
                  : !isFieldValid.fecha
                  ? "El campo solo acepta formato de fecha"
                  : ""
              }
              FormHelperTextProps={{ className: !isFieldValid.fecha ? "Mui-error" : "" }}
              onChange={(e) => handleFieldChange("fecha", e.target.value)}
              required
              disabled={isDisabled}
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              label="Título"
              name="title"
              value={getFieldValue("title")}
              error={!isFieldValid.title}
              helperText={
                getFieldValue("title") === "" && !isFieldValid.title
                  ? "El campo no puede estar vacío"
                  : !isFieldValid.title
                  ? "No se permiten caracteres especiales"
                  : ""
              }
              FormHelperTextProps={{ className: !isFieldValid.title ? "Mui-error" : "" }}
              onChange={(e) => handleFieldChange("title", e.target.value)}
              required
              disabled={isDisabled}
            />
          </Box>
          <Box mb={2}>
            {/* <FormControl fullWidth>
              <InputLabel htmlFor="subcategorySelect">Seleccionar subcategorias</InputLabel>
              <Select
                multiple
                value={selectedSubcategories}
                onChange={handleChange}
                renderValue={(selected) => (
                  <div>
                    {selected
                      .map((id) => {
                        const subcategory = listSubCategories
                          .flatMap((c) => c.subcategories)
                          .find((s) => s.id === id);
                        return subcategory ? subcategory.name : null;
                      })
                      .join(", ")}
                  </div>
                )}
                MenuProps={{
                  PaperProps: {},
                }}
                sx={{
                  width: "100%", // Ajusta el ancho del Select
                  height: "42px", // Aumenta la altura del Select
                }}
              >
                {listSubCategories.map((c) => renderSelectGroup(c))}
              </Select>
            </FormControl> */}
            <FormControl fullWidth error={!isFieldValid.subcategories}>
              <InputLabel htmlFor="subcategorySelect">Seleccionar subcategorías *</InputLabel>
              <Select
                multiple
                value={selectedSubcategories}
                label="Seleccionar subcategorías *"
                onChange={handleChange}
                required
                renderValue={(selected) => (
                  <div>
                    {selected
                      .map((id) => {
                        const subcategory = listSubCategories
                          .flatMap((c) => c.subcategories)
                          .find((s) => s.id === id);
                        return subcategory ? subcategory.name : null;
                      })
                      .join(", ")}
                  </div>
                )}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: 300, // Limita la altura del menú
                      width: "auto", // Ajusta el ancho dinámicamente según el contenido
                      minWidth: 250, // Define un ancho mínimo para evitar que sea demasiado pequeño
                      whiteSpace: "normal", // Permite que las palabras largas se ajusten en varias líneas
                    },
                  },
                }}
                sx={{
                  width: "100%", // Ajusta el ancho del Select
                  height: "42px", // Aumenta la altura del Select
                }}
              >
                {/* Renderiza las opciones de subcategorías */}
                {listSubCategories.map((c) => renderSelectGroup(c))}
              </Select>
              {selectedSubcategories?.length < 1 && !isFieldValid.subcategories && (
                <FormHelperText>Subcategoría requerida</FormHelperText>
              )}
            </FormControl>
          </Box>
          <br />
          <Box mb={2}>
            <TextField
              fullWidth
              label="Descripción"
              name="description"
              value={getFieldValue("description")}
              error={!isFieldValid.description}
              helperText={
                getFieldValue("description") === "" && !isFieldValid.description
                  ? "El campo no puede estar vacío"
                  : !isFieldValid.description
                  ? "No se permiten números ni caracteres especiales"
                  : "" + ` ${getFieldValue("description")?.length || 0} / ${maxLength} caracteres`
              }
              FormHelperTextProps={{ className: !isFieldValid.description ? "Mui-error" : "" }}
              onChange={(e) => handleFieldChange("description", e.target.value)}
              required
              disabled={isDisabled}
              multiline
              rows={2}
              inputProps={{
                maxLength: maxLength,
              }}
              // helperText={`${getFieldValue("description")?.length || 0} / ${maxLength} caracteres`}
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              label="Palabras clave"
              name="keywords"
              value={getFieldValue("keywords")}
              error={!isFieldValid.keywords}
              helperText={
                getFieldValue("keywords") === "" && !isFieldValid.keywords
                  ? "El campo no puede estar vacío"
                  : !isFieldValid.keywords
                  ? "No se permiten caracteres especiales"
                  : ""
              }
              FormHelperTextProps={{ className: !isFieldValid.keywords ? "Mui-error" : "" }}
              onChange={(e) => handleFieldChange("keywords", e.target.value)}
              required
              disabled={isDisabled}
            />
          </Box>
          <Box mb={2}>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Certificable
            </Typography>
            <FormControl
              component="fieldset"
              fullWidth
              required
              error={!isFieldValid.is_certifiable}
            >
              <RadioGroup
                name="is_certifiable"
                value={
                  getFieldValue("is_certifiable") === null
                    ? ""
                    : getFieldValue("is_certifiable")
                    ? "true"
                    : "false"
                }
                onChange={(e) => handleFieldChange("is_certifiable", e.target.value === "true")}
                disabled={isDisabled}
              >
                <FormControlLabel value="true" control={<Radio />} label="Sí" />
                <FormControlLabel value="false" control={<Radio />} label="No" />
              </RadioGroup>
              {!isFieldValid.is_certifiable && (
                <FormHelperText>Certificable es requerido</FormHelperText>
              )}
            </FormControl>
          </Box>
          <Divider variant="middle" />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <MDButton
              type="submit"
              color="success"
              sx={{
                flex: 1,
                mr: 1,
              }}
              disabled={isDisabled}
            >
              Guardar
            </MDButton>
            <MDButton
              onClick={handleClose}
              color="error"
              sx={{
                flex: 1,
                mr: 1,
              }}
              disabled={isDisabled}
            >
              Cancelar
            </MDButton>
          </Box>
        </form>
      </Card>
    </Modal>
  );
}

ActivitiesCreate.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  isDisabled: PropTypes.bool,
  getFieldValue: PropTypes.func,
  handleFieldChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  isFieldValid: PropTypes.shape({
    categories: PropTypes.bool,
    title: PropTypes.bool,
    description: PropTypes.bool,
    is_certifiable: PropTypes.bool,
  }),
  fetchSubCategories: PropTypes.array,
};
