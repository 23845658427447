import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  Icon,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Typography,
  Card,
  FormHelperText,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

export function UserEdit({
  open,
  handleClose,
  datosRecuperados,
  datosEditados,
  isFormModified,
  isDisabled,
  getFieldValue,
  handleFieldChange,
  handleSubmit,
  listRoles,
  isFieldValid,
  handleRoleList,
  setRoleName,
}) {
  const [isFormValid, setIsFormValid] = useState(true);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "400px",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
  };

  // Validación de nombres y apellidos permitiendo tildes
  const validateText = (text) => /^[a-zA-ZÀ-ÿ\s]*$/.test(text); // Permite letras y tildes

  const capitalizeWords = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  // Validar todos los campos necesarios para habilitar el botón de actualización
  useEffect(() => {
    const validateForm = () => {
      const firstName = getFieldValue("first_name");
      const lastName = getFieldValue("last_name");
      const email = getFieldValue("data_types") ? getFieldValue("data_types")[0].value : "";
      const roleId = getFieldValue("role_id");
      const status = getFieldValue("status");

      return validateText(firstName) && validateText(lastName) && email && roleId && status;
    };

    setIsFormValid(validateForm());
  }, [getFieldValue, isFormModified]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card sx={style}>
        <MDBox
          mt={-7}
          color="white"
          bgColor="info"
          variant="gradient"
          borderRadius="lg"
          p={2}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Typography id="modal-modal-title" component="h2">
            Editando usuario: {getFieldValue("name")}
          </Typography>
          <Icon
            sx={{
              fontSize: "2em",
              stroke: "currentColor",
              strokeWidth: "2px",
              cursor: "pointer",
              marginLeft: "auto",
            }}
            onClick={handleClose}
          >
            close
          </Icon>
        </MDBox>
        <Divider variant="middle" />
        <Typography id="modal-modal-title" variant="h5" component="h2">
          Nombres
        </Typography>
        <MDInput
          disabled={isDisabled}
          style={{ marginTop: "5px" }}
          value={getFieldValue("first_name")}
          error={!isFieldValid.first_name}
          helperText={
            getFieldValue("first_name") === "" && !isFieldValid.first_name
              ? "El campo no puede estar vacío"
              : !isFieldValid.first_name
              ? "No se permiten números ni caracteres especiales"
              : ""
          }
          FormHelperTextProps={{ className: !isFieldValid.first_name ? "Mui-error" : "" }}
          onChange={(e) => handleFieldChange("first_name", capitalizeWords(e.target.value))}
        />
        <br />
        <Typography id="modal-modal-title" variant="h5" component="h2">
          Apellidos
        </Typography>
        <MDInput
          disabled={isDisabled}
          style={{ marginTop: "5px" }}
          value={getFieldValue("last_name")}
          error={!isFieldValid.last_name}
          helperText={
            getFieldValue("last_name") === "" && !isFieldValid.last_name
              ? "El campo no puede estar vacío"
              : !isFieldValid.last_name
              ? "No se permiten números ni caracteres especiales"
              : ""
          }
          FormHelperTextProps={{ className: !isFieldValid.last_name ? "Mui-error" : "" }}
          onChange={(e) => handleFieldChange("last_name", capitalizeWords(e.target.value))}
        />
        <br />
        <Typography id="modal-modal-title" variant="h5" component="h2">
          Correo electrónico
        </Typography>
        <MDInput
          disabled={isDisabled}
          style={{ marginTop: "5px" }}
          value={getFieldValue("data_types") ? getFieldValue("data_types")[0].value : ""}
          error={!isFieldValid.data_types}
          helperText={
            getFieldValue("data_types")
              ? getFieldValue("data_types")[0].value === "" && !isFieldValid.data_types
                ? "El campo no puede estar vacío"
                : !isFieldValid.data_types
                ? "Digite un email válido."
                : ""
              : ""
          }
          FormHelperTextProps={{ className: !isFieldValid.data_types ? "Mui-error" : "" }}
          onChange={(e) => handleFieldChange("data_types", e.target.value)}
        />
        <br />
        <Box sx={{ marginRight: "20px" }}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Rol
          </Typography>
          <FormControl sx={{ minWidth: 80 }} error={!isFieldValid.role_id}>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={getFieldValue("role_id")}
              onChange={(e) => {
                const selectedRoleId = e.target.value;
                const selectedRole = handleRoleList.find((role) => role.id === selectedRoleId);
                // handleFieldChange("role_name", selectedRole ? selectedRole.name : "");
                setRoleName(selectedRole ? selectedRole.name : "");
                handleFieldChange("role_id", selectedRoleId);
                // console.log("Role selected: ", selectedRole);
              }}
              style={{ fontSize: "15px", padding: "10px" }}
              disabled={isDisabled}
              displayEmpty
            >
              <MenuItem value={""}>Seleccione...</MenuItem>
              {handleRoleList
                ? handleRoleList.map((role) => (
                    <MenuItem key={role.id} value={role.id}>
                      {role.name}
                    </MenuItem>
                  ))
                : []}
            </Select>
            {!isFieldValid.role_id && <FormHelperText>Rol requerido</FormHelperText>}
          </FormControl>
        </Box>
        <form onSubmit={handleSubmit}>
          <Box display="flex" alignItems="flex-start">
            <Box>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Estado
              </Typography>
              <FormControl error={!isFieldValid.status}>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={getFieldValue("status")}
                  onChange={(e) => handleFieldChange("status", e.target.value)}
                >
                  <FormControlLabel
                    disabled={isDisabled}
                    value={"Activo"}
                    control={<Radio />}
                    label="Activo"
                  />
                  <FormControlLabel
                    disabled={isDisabled}
                    value={"Inactivo"}
                    control={<Radio />}
                    label="Inactivo"
                  />
                </RadioGroup>
                {!isFieldValid.status && <FormHelperText>Estado requerido</FormHelperText>}
              </FormControl>
            </Box>
          </Box>
          <Divider variant="middle" />
          <Box
            style={{
              display: "flex",
              gap: "5px",
              justifyContent: "flex-end",
            }}
          >
            <MDButton onClick={handleClose} color="error">
              Cancelar
            </MDButton>
            <MDButton type="submit" disabled={!isFormModified} color="success">
              Actualizar
            </MDButton>
          </Box>
        </form>
      </Card>
    </Modal>
  );
}

UserEdit.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  datosRecuperados: PropTypes.object,
  isDisabled: PropTypes.bool,
  datosEditados: PropTypes.object,
  getFieldValue: PropTypes.func,
  handleFieldChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  isFormModified: PropTypes.bool,
  listRoles: PropTypes.array,
  isFieldValid: PropTypes.object,
};
