import { fetchWrapper } from "fetchWrapper";

export const createEvidence = async (payload) => {
  try {
    const response = await fetchWrapper(
      `${process.env.REACT_APP_BACKEND_URL}/evidence/create/`,
      {
        method: "POST",
        body: payload,
      },
      true
    );
    // if (response.ok) {
    // const result = await response.json();

    return response;
    // } else {
    //   throw new Error(`Error del servidor al realizar la solicitud`);
    // }
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    const status = false;
    return { success: false, message: error.message || message };
  }
};

export async function EvidenceListService(id, pageIndex, pageSize) {
  try {
    const response = await fetchWrapper(
      `${process.env.REACT_APP_BACKEND_URL}/evidence/${id}/?page=${pageIndex}&pageSize=${pageSize}`,
      {
        method: "GET",
      }
    );
    // if (response.ok) {
    //   const result = await response.json();

    return response;
    // } else {
    //   throw new Error(`Error del servidor al realizar la solicitud`);
    // }
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    const status = false;
    return { success: false, message: error.message || message };
  }
}

export const deleteEvidence = async (evidenceId) => {
  try {
    const response = await fetchWrapper(
      `${process.env.REACT_APP_BACKEND_URL}/evidence/${evidenceId}/delete/`,
      {
        method: "DELETE",
      }
    );
    // if (response.ok) {
    // return { success: true };
    return response;
    // } else {
    //   throw new Error("Error al eliminar la evidencia.");
    // }
  } catch (error) {
    return {
      success: false,
      message: error.message || "Error del servidor al realizar la solicitud",
    };
  }
};
