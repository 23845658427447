import MDInput from "components/MDInput";
import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Alert,
  FormHelperText,
  Checkbox,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import MDButton from "components/MDButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";

import BackspaceIcon from "@mui/icons-material/Backspace";
import ClearIcon from "@mui/icons-material/Clear";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

export default function Filter({
  dataFilter,
  filter,
  handleFilter,
  isVisible,
  handleChange,
  search,
  filterType,
  handleFilterType,
  isVisibleSelectDateInput,
  isVisibleSelectDate,
  isVisibleType,
  isVisibleSubcategory,
  isVisibleCategory,
  dateFrom,
  dateUntil,
  handleChangeDateFrom,
  handleChangeDateUntil,
  handleClickSubmit,
  dataTypeCard,
  dataStatus,
  dataSourceTypes,
  handleAddFilter,
  filters,
  showErrorAlert,
  handleDeleteFilter,
  isFieldValid,
  filterConfig,
  handleFieldChange,
  listSubCategories = [],
  categoriesList = [],
}) {
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);

  // useEffect(() => {
  //   // If you need to perform any action when listSubCategories changes
  // }, [listSubCategories]);

  const handleChangeSubcategory = (event) => {
    // console.log(event.target.value);
    const selectedItem = event.target.value[event.target.value.length - 1]; // Obtener el último elemento seleccionado o deseleccionado

    setSelectedSubcategories((prevSelected) => {
      const isAlreadySelected = prevSelected.some((item) => item.id === selectedItem.id);

      if (isAlreadySelected) {
        // Si ya está seleccionado, lo eliminamos (deselección)
        handleFieldChange(
          "subcategories",
          prevSelected.filter((item) => item.id !== selectedItem.id)
        );
        return prevSelected.filter((item) => item.id !== selectedItem.id);
      } else {
        // Si no está seleccionado, lo añadimos (selección)
        handleFieldChange("subcategories", [...prevSelected, selectedItem]);
        return [...prevSelected, selectedItem];
      }
    });
    // handleFieldChange("subcategories", selectedSubcategories);
  };

  const renderSelectGroup = (category) => {
    return [
      <ListSubheader key={category.id}>{category.name}</ListSubheader>,
      ...category.subcategories.map((sub) => (
        <MenuItem key={sub.id} value={{ id: sub.id, name: sub.name }}>
          <Checkbox checked={selectedSubcategories.some((s) => s.id === sub.id)} />
          <ListItemText primary={sub.name} />
        </MenuItem>
      )),
    ];
  };
  return (
    <>
      {/* {showErrorAlert && <Alert severity="error">El filtro ya está en la lista o es vacío</Alert>} */}
      {filters.length !== 0 && (
        <div>
          {filters.map((item, index) => (
            <MDButton
              endIcon={<BackspaceIcon />}
              variant="contained"
              color="error"
              size="medium"
              style={{ marginTop: "25px" }}
              key={index}
              sx={{ m: 1 }} // Establecer el color del texto a blanco
              onClick={() => handleDeleteFilter(item.id)}
            >
              {item.label}
            </MDButton>
          ))}
        </div>
      )}
      <div style={{ marginTop: "10px", display: "flex", alignItems: "center", flexWrap: "wrap" }}>
        <FormControl style={{ left: "20px" }} variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="demo-simple-select-label">Filtrado por...</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filter}
            label="Filtrado por..."
            onChange={handleFilter}
          >
            <MenuItem value="">NINGUNO</MenuItem>
            {filterConfig.map((item, index) => (
              <MenuItem key={index} value={item.dbField}>
                {item.visibleName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {isVisible && (
          <>
            <MDInput
              error={!isFieldValid.search}
              helperText={!isFieldValid.search ? "Ingrese un valor." : ""}
              style={{
                left: "20px",
                marginRight: "5px",
              }}
              label="Buscar"
              onChange={handleChange}
              value={search}
            />
            <MDButton
              style={{ left: "20px" }}
              variant="gradient"
              size="small"
              color="info"
              onClick={handleAddFilter}
            >
              Agregar Filtro
            </MDButton>
          </>
        )}
        <>
          {isVisibleSelectDate && (
            <>
              <MDInput
                type="date"
                label="Desde"
                style={{
                  left: "20px",
                  marginRight: "5px",
                }}
                value={dateFrom}
                onChange={handleChangeDateFrom}
              />
              <MDInput
                type="date"
                label="Hasta"
                value={dateUntil}
                style={{
                  left: "20px",
                  marginRight: "5px",
                }}
                onChange={handleChangeDateUntil}
              />
              <MDButton
                style={{ left: "20px" }}
                variant="gradient"
                color="info"
                size="small"
                onClick={handleAddFilter}
              >
                Agregar Filtro
              </MDButton>
            </>
          )}
        </>
        {/* {isVisibleType && filterSourceData && (
          <>
            <FormControl>
              <InputLabel>Selecciona...</InputLabel>
              <Select
                value={search}
                style={{
                  left: "20px",
                  marginRight: "5px",
                }}
                onChange={handleFilterType}
              >
                <MenuItem value="">Ninguno</MenuItem>
                {filterSourceData[selectedFilter.source].map((item) => (
                  <MenuItem key={item.id} value={item.name}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <MDButton
              style={{ left: "20px" }}
              variant="gradient"
              size="small"
              color="info"
              onClick={handleAddFilter}
            >
              Agregar Filtro
            </MDButton>
          </>
        )} */}
        {isVisibleType && isVisibleSubcategory && (
          <>
            <FormControl style={{ width: "auto" }} sx={{ m: 1, minWidth: 220 }}>
              {/* <InputLabel style={{ left: "20px" }} htmlFor="subcategorySelect">
                Seleccionar subcategorias
              </InputLabel> */}
              <InputLabel id="demo-simple-select-label123" style={{ left: "20px" }}>
                Seleccionar subcategorias
              </InputLabel>
              <Select
                labelId="demo-simple-select-label123"
                multiple
                value={selectedSubcategories}
                onChange={handleChangeSubcategory}
                style={{
                  marginLeft: "20px",
                }}
                renderValue={(selected) => (
                  <div>
                    {selected
                      .map((item) => item.name) // `item` es un objeto con `{ id, name }`
                      .filter((name) => name !== null) // Filtra valores nulos para evitar problemas
                      .join(", ")}
                  </div>
                )}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: 300, // Limita la altura del menú
                      width: "auto", // Ajusta el ancho dinámicamente según el contenido
                      minWidth: 250, // Define un ancho mínimo para evitar que sea demasiado pequeño
                      whiteSpace: "normal", // Permite que las palabras largas se ajusten en varias líneas
                    },
                  },
                }}
                sx={{
                  width: "100%", // Ajusta el ancho del Select
                  height: "42px", // Aumenta la altura del Select
                }}
              >
                {/* Renderiza las opciones de subcategorías */}
                {listSubCategories.map((c) => renderSelectGroup(c))}
              </Select>
            </FormControl>
            <MDButton
              style={{ left: "20px" }}
              variant="gradient"
              size="small"
              color="info"
              onClick={handleAddFilter}
            >
              Agregar Filtro
            </MDButton>
          </>
        )}
        {isVisibleType && isVisibleCategory && (
          <>
            <FormControl style={{ width: "auto", left: "20px" }} sx={{ m: 1, minWidth: 220 }}>
              <InputLabel style={{ left: "20px" }} id="demo-simple-select-label321">
                Selecciona...
              </InputLabel>
              <Select
                value={JSON.stringify(search) || ""}
                style={{
                  marginLeft: "20px",
                  marginRight: "5px",
                }}
                onChange={handleFilterType}
                label="Selecciona..."
                labelId="demo-simple-select-label321"
                id="demo-simple-select321"
              >
                <MenuItem value="">Ninguno</MenuItem>
                {categoriesList.map((item) => (
                  <MenuItem key={item.id} value={JSON.stringify({ id: item.id, name: item.name })}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <MDButton
              style={{ left: "20px" }}
              variant="gradient"
              size="small"
              color="info"
              onClick={handleAddFilter}
            >
              Agregar Filtro
            </MDButton>
          </>
        )}
      </div>
      <MDButton
        style={{ left: "20px", marginTop: "10px" }}
        variant="gradient"
        color="info"
        onClick={handleClickSubmit}
      >
        Buscar
      </MDButton>
    </>
  );
}
