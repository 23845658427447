import {
  Box,
  Divider,
  Icon,
  Modal,
  Typography,
  Card,
  TextField,
  Button,
  FormHelperText,
} from "@mui/material";
import { useState } from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

export function EvidenceCreate({ open, handleClose, onSubmit, isFieldValid, handleChangeValid }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "600px",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
  };

  const [formValues, setFormValues] = useState({
    description: "",
    file: null,
  });

  const [errors, setErrors] = useState({
    description: false,
    file: false,
  });

  const handleChange = (event) => {
    const { name, value, files } = event.target;

    // Formatear la descripción si es el campo correspondiente
    const formattedValue =
      name === "description" ? value.charAt(0).toUpperCase() + value.slice(1).toLowerCase() : value;

    setFormValues({
      ...formValues,
      [name]: files ? files[0] : formattedValue, // Si hay archivos, guarda el archivo, si no, guarda el valor formateado
    });

    if (name === "description") handleChangeValid(name, formattedValue);
  };

  const validateForm = () => {
    const newErrors = {
      description: !formValues.description, // Error si está vacío
      file: !formValues.file, // Error si no se ha subido un archivo
    };

    setErrors(newErrors);

    // Retornar true si no hay errores
    return !newErrors.description && !newErrors.file;
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    // Validar el formulario
    if (validateForm()) {
      onSubmit(formValues); // Si la validación pasa, llama a onSubmit
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setFormValues({ description: "", file: null }); // Reset form values when modal opens
        handleClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card sx={style}>
        <MDBox
          mt={-7}
          color="white"
          bgColor="info"
          variant="gradient"
          borderRadius="lg"
          p={2}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Typography id="modal-modal-title" component="h2">
            Crear evidencia
          </Typography>
          <Icon
            sx={{
              fontSize: "2em",
              stroke: "currentColor",
              strokeWidth: "2px",
              cursor: "pointer",
              marginLeft: "auto",
            }}
            onClick={() => {
              setFormValues({ description: "", file: null }); // Reset form values when modal opens
              handleClose();
            }}
          >
            close
          </Icon>
        </MDBox>
        <Divider variant="middle" />
        <Box component="form" onSubmit={handleFormSubmit} sx={{ mt: 2 }}>
          <TextField
            fullWidth
            label="Descripción"
            variant="outlined"
            name="description"
            value={formValues.description}
            onChange={handleChange}
            multiline
            rows={4}
            sx={{ mb: 2 }}
            error={errors.description || !isFieldValid.description}
            helperText={
              (errors.description && "La descripción es obligatoria") ||
              (!isFieldValid.description && "No se permiten números ni caracteres especiales")
            }
          />
          {/* <Button variant="gradient" component="label" sx={{ mb: 2 }}>
            Subir archivo
            <input type="file" name="file" hidden onChange={handleChange} />
          </Button> */}
          <>
            <MDInput
              style={{ marginTop: "5px" }}
              label=""
              type="file"
              // error={!errors.file}
              helperText={!formValues.file && "El archivo es requerido"}
              FormHelperTextProps={{
                className: !formValues.file ? "Mui-error" : "",
              }}
              name="file"
              onChange={handleChange}
            ></MDInput>
            {/* {!errors.file && (
              <Typography color="error" variant="caption" sx={{ mb: 2 }}>
                Es necesario subir un archivo.
              </Typography>
            )} */}
            <label
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "100px",
                display: "inline-block",
              }}
              title={formValues.file ? formValues.file.name : ""}
            >
              {formValues.file ? formValues.file.name : ""}
            </label>
          </>
          <Divider variant="middle" />
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <MDButton
              color="error"
              sx={{
                flex: 1,
                mr: 1,
              }}
              onClick={() => {
                setFormValues({ description: "", file: null }); // Reset form values when modal opens
                handleClose();
              }}
            >
              Cancelar
            </MDButton>
            <MDButton
              type="submit"
              color="success"
              sx={{
                flex: 1,
                ml: 1,
              }}
            >
              Guardar
            </MDButton>
          </Box>
          {/* <MDButton type="submit" variant="gradient" color="info" fullWidth>
            Crear evidencia
          </MDButton> */}
        </Box>
      </Card>
    </Modal>
  );
}
