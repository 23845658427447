// fetchWrapper.js
export async function fetchWrapper(url, options = {}, isFile = false) {
  // Obtener el token del localStorage
  const token = sessionStorage.getItem("token");

  // Asegurar que las cabeceras existen
  options.headers = options.headers || {};

  // Añadir el token al encabezado si existe
  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  }

  // Asegurar que el Content-Type sea JSON si no está definido
  if (!isFile) {
    // options.headers["Content-Type"] = "multipart/form-data";
    if (!options.headers["Content-Type"]) {
      options.headers["Content-Type"] = "application/json";
    }
  }

  try {
    const response = await fetch(url, options);

    // Verificar si la respuesta es exitosa (2xx)
    if (response.ok) {
      return await response.json();
    } else {
      // Si el servidor devuelve un error, tratar de obtener el mensaje de error de la respuesta
      const errorDetails = await response.json();
      throw new Error(errorDetails.message || "Error del servidor al realizar la solicitud");
    }
  } catch (error) {
    // Captura el error y devuelve un objeto con éxito false y el mensaje de error
    return {
      success: false,
      message: error.message || "Error del servidor al realizar la solicitud",
    };
  }
}
