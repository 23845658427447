/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function Breadcrumbs({ icon, title, route, light }) {
  const routes = route.slice(0, -1);
  const routeName = useLocation().pathname.split("/").slice(1);

  function isUUID(str) {
    const regex =
      /^[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}$/;
    return regex.test(str);
  }

  function getDisplayName(el, index) {
    if (el === "Fuentes") {
      return "Fuentes";
    } else if (el === "Actividades-P") {
      return "Actividades";
    } else if (el === "Actividades-E") {
      return "Actividades";
    } else if (isUUID(el) && index === 1) {
      return "Archivos por fuente";
    } else if (isUUID(el) && index === 2) {
      return "Carnets por archivo";
    } else if (el === "verify") {
      return "Verificar carnets";
    } else {
      return el;
    }
  }

  function getDisplayNameEnd(title) {
    if (isUUID(title) && routeName.length === 2 && routeName[0] === "Actividades-P") {
      return "Participantes";
    } else if (isUUID(title) && routeName.length === 2 && routeName[0] === "Actividades-E") {
      return "Evidencias";
    } else if (isUUID(title) && routeName.length === 2 && routeName[0] === "Roles") {
      return "Editar rol";
    } else if (isUUID(title) && routeName.length === 3 && routeName[0] === "Fuentes") {
      return nameSource;
    } else if (title === "Actividades-P") {
      return "Actividades";
    } else if (title === "Actividades-E") {
      return "Actividades";
    } else if (title === "Bienvenida" || title === "bienvenida") {
      return "Bienvenida";
    }

    if (isUUID(title) && routeName.length === 2 && routeName[0] === "verify") {
      return "Detalle del usuario";
    } else {
      return title;
    }
  }
  let fullPath = "";
  return (
    <MDBox mr={{ xs: 0, xl: 8 }}>
      <MuiBreadcrumbs
        sx={{
          "& .MuiBreadcrumbs-separator": {
            color: ({ palette: { white, grey } }) => (light ? white.main : grey[600]),
          },
        }}
      >
        <Link to="/">
          <MDTypography
            component="span"
            variant="body2"
            color={light ? "white" : "dark"}
            opacity={light ? 0.8 : 0.5}
            sx={{ lineHeight: 0 }}
          >
            <Icon>{icon}</Icon>
          </MDTypography>
        </Link>
        {routes.map((el, index) => {
          fullPath += `/${el}`;
          return (
            <Link to={`/${el}`} key={index}>
              <MDTypography
                component="span"
                variant="button"
                fontWeight="regular"
                textTransform="capitalize"
                color={light ? "white" : "dark"}
                opacity={light ? 0.8 : 0.5}
                sx={{ lineHeight: 0 }}
              >
                {getDisplayName(el, index)}
              </MDTypography>
            </Link>
          );
        })}
        <MDTypography
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          color={light ? "white" : "dark"}
          sx={{ lineHeight: 0 }}
        >
          {getDisplayNameEnd(title)}
        </MDTypography>
      </MuiBreadcrumbs>
      {/* <MDTypography
        fontWeight="bold"
        textTransform="capitalize"
        variant="h6"
        color={light ? "white" : "dark"}
        noWrap
      >
        {title.replace("-", " ")}
      </MDTypography> */}
    </MDBox>
  );
}

// Setting default values for the props of Breadcrumbs
Breadcrumbs.defaultProps = {
  light: false,
};

// Typechecking props for the Breadcrumbs
Breadcrumbs.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  route: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  light: PropTypes.bool,
};

export default Breadcrumbs;
